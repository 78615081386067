/**
 * This is the component for the GHG Reductions graph in the dashboard.
 * 
 * Parent: Dashboard.js
 */

import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  ReferenceLine,
} from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import useProForma from "../../store/useProForma";
import { axisStyle, labelStyle } from "./styles";
import useGraphState from "store/useGraphState";
import { useMediaQuery } from "react-responsive";
import { PRIMARY_COLOR,SECONDARY_GRAPH_COLOR } from "colors";


const transformData = (ghgReductions) => {
  const data = [];

  Object.keys(ghgReductions).forEach((year) => {
    data.push({
      year: parseInt(year, 10),
      ghgReductions: ghgReductions[year],
    });
  });

  return data;
};

const GHGReductionsGraph = () => {
  const { yearHovered } = useGraphState();

  const { controls, proFormaCalcs, allSitesProFormaCalcs, years } =
    useProForma();
  const { EVAL_YEAR } = years;
  const { subtractedReductions = {}, ghgReductions = {} } = proFormaCalcs;
  const { subtractedReductions: allSitesGHGReductions = {} } =
    allSitesProFormaCalcs;
  const maxGHGReductions = Math.max(...Object.values(allSitesGHGReductions));
  const yAxisMax = Math.ceil(maxGHGReductions / 5) * 5;

  const data = transformData(subtractedReductions);
  const allSitesData = transformData(allSitesGHGReductions);



  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });

  return (
    <div className="flex flex-col bg-white rounded-2xl p-4 h-[280px] border">
      <div className="flex gap-2 text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal whitespace-nowrap">
        <img src="ghgicon.svg" width="20"></img>Greenhouse Gases
      </div>
      <div className="flex h-[280px] lg:flex-col lg:h-[350px]">
        <div className="flex-none flex flex-col justify-start gap-4 w-[200px] lg:w-3/4 lg:m-auto">
          <div className="lg:justify-start lg:w-full lg:flex-row">
            <div className="flex items-end pt-4 lg:pt-0">
              <div className="text-[#1b1c1b] text-[35px] font-semibold font-['Inter'] leading-[42px]">
                {Math.round(ghgReductions[EVAL_YEAR])}
              </div>
              <div className="text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal">
                MTCO<sub>2</sub>e
              </div>
            </div>
            <div className="text-[#7e817d] text-[13px] font-normal font-['Roboto'] leading-none">
              Reductions per year, {EVAL_YEAR}
            </div>
          </div>
          {/* <div>
          <div className="flex items-end">
            <div className="text-[#1b1c1b] text-[35px] font-semibold font-['Inter'] leading-[42px]">
              316
            </div>
            <div className="text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal">
              MTCO<sub>2</sub>e/yr
            </div>
          </div>
          <div className="text-[#7e817d] text-[13px] font-normal font-['Roboto'] leading-none">
            Emissions, 2024
          </div>
        </div> */}
        </div>

        <div className="flex-grow relative lg:h-[150px] lg:pt-2">
          {!isTabletOrMobile && (
            <div
              style={labelStyle}
              className="absolute -rotate-90 origin-top-left left-8 bottom-16 transform -translate-y-1/2 pb-2 text-sm text-gray-600 "
            >
              GHG Emissions (MTCO<sub>2</sub>e)
            </div>
          )}
          <ResponsiveContainer width="100%" height="100%">
            <LineChart className="" data={data} margin={{ left: 20 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="year"
                style={axisStyle}
                stroke="#7E817D"
                axisLine={false}
              />
              <YAxis
                axisLine={false}
                stroke="#7E817D"
                style={axisStyle}
                tickFormatter={(value) => Math.round(value)}
                tickCount={4}
              ></YAxis>
              <Tooltip formatter={(value) => Math.round(value)} />
              <Legend align="right" wrapperStyle={{ fontSize: "12px" }} />
              <ReferenceLine x={yearHovered ?? EVAL_YEAR} stroke={PRIMARY_COLOR}/>
              {controls.site !== "All Sites" && (
                <Line
                  strokeWidth={2}
                  type="monotone"
                  dataKey="ghgReductions"
                  stroke={SECONDARY_GRAPH_COLOR}
                  name={`${controls.site}`}
                />
              )}
              {allSitesData && (
                <Line
                  strokeWidth={2}
                  type="monotone"
                  data={allSitesData}
                  dataKey="ghgReductions"
                  stroke={PRIMARY_COLOR}
                  name="All Sites"
                />
              )}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default GHGReductionsGraph;
