/**
 * Contins styles for labels and axis for the graphs in the dashboard
 * 
 */

export const labelStyle = {
  fill: "black",
  textAnchor: "middle",
  textAlign: "right",
  fontFamily: "Roboto",
  fontSize: "11px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "120%",
};

export const axisStyle = {
  color: "#FFFFFF",
  fontFamily: "Roboto",
  fontSize: "13px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "120%" /* 15.6px */,
};
