/**
 * Manages the state of the navbar (open or closed)
 */


import {create} from 'zustand';

const useNavState = create((set) => ({
    isOpen: true,
    toggleNav: () => set((state) => ({ isOpen: !state.isOpen })),
    setNav: (value) => set({ isOpen: value }),
}));

export default useNavState;