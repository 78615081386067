/**
 * @fileoverview API functions for user metadata
 * 
 */


import useAuthStore from "../store/useAuthStore";
export const createUserMetadata = async (userId) => {
    const response = await fetch(
        `${process.env.REACT_APP_API_ROUTE}api/user-metadata/${userId}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: useAuthStore.getState().getToken(),
            },
            // You can pass any additional headers or options here
        }
    );
    return response;
};

export const getUserMetadata = async (userId) => {
    const response = await fetch(
        `${process.env.REACT_APP_API_ROUTE}api/user-metadata/${userId}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: useAuthStore.getState().getToken(),
            },
            // You can pass any additional headers or options here
        }
    );
    return await response.json();
};
export const updateMetadata = async (userId, metadata) => {
    const response = await fetch(
        `${process.env.REACT_APP_API_ROUTE}api/user-metadata/${userId}`,
        {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: useAuthStore.getState().getToken(),
            },
            body: JSON.stringify(metadata),
        }
    );
    return await response.json();
}