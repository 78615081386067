/**
 * @fileoverview used to create a supabase client, should not be modified
 */
import { createClient } from '@supabase/supabase-js';

const supabaseUrl=`${process.env.REACT_APP_SUPABASE_URL}`
const supabaseAnonKey=`${process.env.REACT_APP_SUPABASE_ANON}`

const supabase = createClient(supabaseUrl, supabaseAnonKey);

export default supabase



