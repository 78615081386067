/**
 * @fileoverview This store manages the table state for the fleet table, phase table, and year table
 * 
 * Stores things like column width, sorting, visibility, etc
 * 
 * This file should not be modified (unless adding a new table)
 */

import {create} from 'zustand';

const useColumnState = create((set) => ({
    fleet: {},
    setFleetState: (fleet) => set({ fleet }),
    phaseColumns: {},
    setPhaseColumns: (phaseColumns) => set({ phaseColumns }),
    yearColumns: {},
    setYearColumns: (yearColumns) => set({ yearColumns }),
}));

export default useColumnState;