/**
 * @fileoverview Manages the state of the year hovered or selected in the dashboard
 *
 * Allows for us to have the reference line in all of the graphs for the selected year
 */

import { create } from "zustand";

const useGraphState = create((set) => ({
  yearHovered: null,
  setHoveredYear: (year) => {
    set({ yearHovered: year });
  },
}));

export default useGraphState;
