/**
 * This is the component for the horizontal bar graph for the ports in the dashboard.
 */


import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import useProForma from "store/useProForma";

const colorMap = {
    range_1: "#7095f0",
    range_2: "#79d161",
    range_3: "#ea5919",
    range_4: "#b5625b",
}



const PortsHorizontalGraph = () => {
  const { phases = {}, controls = {}, cityInfo = {} } = useProForma();
  const [data, setData] = useState([]);
  const [rangeName, setRangeNames] = useState({});
  const [maxVal,setMaxVal] = useState(0);

  useEffect(() => {
    if (!phases.phases || !controls) {
      return;
    }
    const innerPhase = phases.phases;
    let arr = [0, 0, 0, 0];
    for (let i = 0; i < innerPhase.length; i++) {
      arr[0] += innerPhase[i].port_less_than_10_kw;
      arr[1] += innerPhase[i].port_10_20_kw;
      arr[2] += innerPhase[i].port_25_kw;
      arr[3] += innerPhase[i].port_180_200_kw;
    }

    let rangeNames = {
      range_1: "<10 kW",
      range_2: "10-20 kW",
      range_3: "25 kW",
      range_4: "180-200 kW",
    };

    rangeNames.range_1 = cityInfo.range_1
      ? cityInfo.range_1
      : rangeNames.range_1;
    rangeNames.range_2 = cityInfo.range_2
      ? cityInfo.range_2
      : rangeNames.range_2;
    rangeNames.range_3 = cityInfo.range_3
      ? cityInfo.range_3
      : rangeNames.range_3;
    rangeNames.range_4 = cityInfo.range_4 ? cityInfo.range_4 : rangeNames.range_4;
    setRangeNames(rangeNames);
    setData([
      {
        name: "Port Counts",
        [rangeNames.range_1]: arr[0],
        [rangeNames.range_2]: arr[1],
        [rangeNames.range_3]: arr[2],
        [rangeNames.range_4]: arr[3],
      },
    ]);
    setMaxVal(arr[0]+arr[1]+arr[2]+arr[3]);
  }, [phases, controls]);

  return (
    <div className="w-full">
      <ResponsiveContainer width="100%" height={50}>
        <BarChart layout="vertical" data={data}>
          <XAxis hide type="number" />
          <YAxis hide dataKey="name" type="category"/>
          <Legend align="left" wrapperStyle={{ fontSize: "12px" }} />
          {Object.entries(rangeName).map(([key, value]) => (
            <Bar
              key={value}
              dataKey={value}
              stackId="a"
              fill={colorMap[key]}
              radius={[5, 5, 5, 5]}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PortsHorizontalGraph;
