/**
 * @fileoverview Outermost component for the Year Over Year or Trends page
 */

import React, { useEffect, useState } from "react";
import useAuthStore from "../store/useAuthStore";
import YearGrid from "components/year-over-year/YearGrid";
import { Alert, AlertDescription, AlertTitle } from "../components/ui/alert";
import { AlertCircle } from "lucide-react";
import useCache from "../store/useCache";
import useProForma from "../store/useProForma";
import SiteDropdown from "../components/dashboard/SiteDropdown";
import AdvControlsPopup from "../components/advanced-controls/AdvControlsPopup";
import ToggleNav from "components/ToggleNav";

function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}

const YearOverYear = () => {
  const { user } = useAuthStore();
  const {
    yearOverYear,
    phases,
    years,
  } = useProForma();
  const [siteOptions, setSiteOptions] = useState([]);
  const { filteredPhases } = phases;

  const { estimatedElectricVehicles } = yearOverYear;
  const [error, setError] = useState(false);


  /**
   * @function useEffect - hook that detects when there are vehicles electrified before charging stations
   * and sets the error state to true if so
   */
  /*useEffect(() => {
    if (!filteredPhases) return;
    const min = Math.min(...filteredPhases.map((phase) => phase.year));
    const hasError = Object.keys(estimatedElectricVehicles).some((year) => {
      return year < min && estimatedElectricVehicles[year] > 0;
    });
    setError(hasError);
  }, [filteredPhases, estimatedElectricVehicles]);
*/
  if (!siteOptions) {
    return <div>Please log in</div>;
  }

  return (
    <div className="h-screen relative">
      <div
        style={{ width: "inherit", minWidth: "inherit" }}
        className="flex bg-background_gray z-40 p-[20px] box-border w-full sticky top-0 max-w-screen"
      >
        <div className="flex flex-col items-start">
        <div className="flex justify-between gap-2">
          <ToggleNav/>
        </div>
          <SiteDropdown />
          <div className="text-[#8a8d89] text-[17px] font-medium font-['Inter'] leading-tight">Detailed data about your systems used to inform our advanced modeling</div>
        </div>
        <AdvControlsPopup />
        {error && (
          <Alert className="ml-4 w-200" variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Warning</AlertTitle>
            <AlertDescription>
              There are vehicles electrified before charging stations
            </AlertDescription>
          </Alert>
        )}
      </div>
      <div className="flex rounded-3xl gap-6 bg-gray2 p-6 h-[calc(100vh-130px)]" >
        {!isEmptyObject(years) && <YearGrid />}
      </div>
    </div>
  );
};

export default YearOverYear;
