import useAuthStore from "../store/useAuthStore";


/**
 * @function createControls - function to add control to database
 * 
 * @param {*} defaultControlsData object containing control data to update
 * @returns api response
 */
export const createControls = async (defaultControlsData) => {
  return await fetch(`${process.env.REACT_APP_API_ROUTE}api/controls`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: useAuthStore.getState().getToken(),
    },
    body: JSON.stringify(defaultControlsData),
  });
};
