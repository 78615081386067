/**
 * @fileoverview Set password page for first time login
 * 
 * Set first login status on supabase
 */

import React, { useState } from "react";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "../components/ui/card";
import { useToast } from "../components/ui/use-toast";
import useAuthStore from "../store/useAuthStore";
import supabase from "../supabaseClient";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
  DialogClose,
} from "../components/ui/dialog";
import { useNavigate } from "react-router-dom";
import {updateMetadata} from "../api/usermetadataapi"

const SetPassword = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { user } = useAuthStore();

  // state variables for storing the password and confirm password inputs
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passwordUpdated, setPasswordUpdated] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };
  /**
  *Controls whether dialogue box should be shown
  */
 const [showDialog, setShowDialog] = useState(false); 

  /**
  * @function validatePassword - Ensures password has upper case, number, special character, and minimum length
  */
  const validatePassword = (password) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasMinLength = password.length >= 8;
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  
    return hasUpperCase && hasNumber && hasMinLength && hasSymbol;
  };

  /**
   * @function handleSubmit - Handles the form submission, updates the user's password
   * displays error message if passwords do not match or if there are other issues
   */
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    if (!user) {
      toast({
        title: "Error",
        description: "No user is currently signed in.",
        variant: "destructive",
      });
      return;
    }
    if (!validatePassword(password)) {
      toast({
        title: "Error",
        description: "Password must be at least 8 characters long, include at least one capital letter (A-Z), include at least one number, and at least one special character.",
        variant: "destructive",
      });
      return;
    }
    if (password !== confirmPassword) {
      toast({
        title: "Error",
        description: "Passwords do not match. Please try again.",
        variant: "destructive",
      });
      return;
    }
  // If all validations pass, show the Terms and Conditions dialog  
    setShowDialog(true);
   
  };
  const handleFinalSubmit = async () => {
    try {
      const { error } = await supabase.auth.updateUser({ password: password });
      if (error) throw error;
  
      toast({
        title: "Success",
        description: "Your password has been successfully updated.",
      });
      setPassword("");
      setConfirmPassword("");
      setPasswordUpdated(true);
      updateMetadata(user.id, { first_login: false });
      navigate("/");
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Failed to update password. Please try again.",
        variant: "destructive",
      });
    } finally {
      setShowDialog(false); // Close the dialog after the process is complete
    }
  };
  
  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <Card className="w-[500px] p-4">
        <CardHeader className="pb-14">
          <CardTitle className="text-optonyprimary">Set New Password</CardTitle>
        </CardHeader>

        <CardContent>
          <div className="grid w-full items-center gap-4">
            <div className="flex flex-col space-y-1.5">
              <Label htmlFor="password">New Password</Label>
              <Input
                id="password"
                type="password"
                value={password}
                onChange={handlePasswordChange}
                placeholder="Enter your new password"
              />

              <Label htmlFor="password">Confirm Password</Label>
              <Input
                id="password"
                type="password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                placeholder="Confirm your new password"
              />
            </div>
          </div>
        </CardContent>
        <CardFooter> {/*This button triggers the validation and shows the dialog*/}
        <Button onClick={handleSubmit}>Update Password</Button>
        </CardFooter>
    </Card>

    {/* Conditionally render the dialog */}
    {showDialog && (
          <Dialog open={showDialog} onOpenChange={(open) => setShowDialog(open)}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Terms and Conditions</DialogTitle>
              </DialogHeader>
              <div className="max-h-60 overflow-y-auto mb-4 p-4 border border-gray-200" style={{ fontFamily: 'Times New Roman, Times, serif' }}>
                <p>
                  By accessing and using this digital tool, you acknowledge and
                  agree to the following Terms and Conditions:
                </p>
                <p>
                  Upon first signing into your account, you may need to create a
                  new account password to use all or part of the Pro Forma tool.
                  Your username and password are for Optony client use only and
                  should be kept confidential. You understand that you are
                  responsible for all authorized or unauthorized use of your
                  username and password. Notify the Optony team immediately if
                  your account access information is lost or stolen, or if you
                  believe there has been unauthorized access to your account.
                </p>
                <p>
                  By creating and using an account on this platform, you consent
                  to host your data on our servers, and you are solely
                  responsible for the content and accuracy of the data you
                  upload. There may be information on the digital tool that
                  contains errors, inaccuracies, or omissions, including
                  descriptions, pricing estimates, projections, financial
                  information, and various other information. Optony reserves
                  the right to correct any errors, inaccuracies, or omissions
                  and to change or update the information available on the
                  digital tool at any time, without prior notice.
                </p>
                <p>
                  Optony shall not be held liable for any direct, indirect,
                  incidental, special, consequential, or punitive damages
                  arising out of your use of this tool, including but not
                  limited to any errors or omissions in any content or data, any
                  loss or damage of any kind incurred as a result of the use of
                  any content or data uploaded, transmitted, or otherwise made
                  available via this tool. Optony will not be liable to you or
                  any third party for any modification, suspension, or
                  discontinuance of this digital tool. By using this digital
                  tool, you acknowledge and accept the inherent risks of data
                  transmission over the internet. We reserve the right to modify
                  these terms at any time, without prior notice.
                </p>
                <p>
                  You are required to provide a digital signature, which will be
                  recorded and stored securely, serving as your acknowledgment
                  and agreement to these terms and conditions. By clicking
                  "Accept" and providing your digital signature, you acknowledge
                  that you have read, understood, and agree to be bound by these
                  terms and conditions.
                </p>
                {/* Add the actual terms and conditions text here */}
              </div>
              <DialogFooter>
                <DialogClose>
                  <Button onClick={handleFinalSubmit}>Agree</Button>
                </DialogClose>

                <DialogClose>
                  <Button variant="secondary" onClick={() => setShowDialog(false)}>Cancel</Button>
                </DialogClose>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        )}
    </div>
  );
};

export default SetPassword;
