/**
 * This store manages the user's authentication state.
 * 
 * Functions for logging in and out, and checking if the user is logged in.
 * 
 */

import { create } from "zustand";
import supabase from "../supabaseClient";
import useProForma from "./useProForma";
import { getUserMetadata } from "../api/usermetadataapi";


const useAuthStore = create((set, get) => ({
  user: null,
  controlsData: null,
  loading: true,
  data: [],
  message: "",
  session: null,

  /**
   * @function getToken - Returns the token for the user
   */
  getToken: () => {
    return `Bearer ${get().session.access_token}`
  },
  
  /**
   * @function login - Logs in the user with the given email
   * @param {*} email 
   * @param {*} password 
   * @returns object with information on if it is first Login
   */
  login: async (email, password) => {
    const { fetchAndUpdateFleet } = useProForma.getState();
    set({ loading: true });
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) throw error;
      set({ session: data.session });
      set({ user: data.user });
      fetchAndUpdateFleet();
      const metadata = await getUserMetadata(data.user.id)
      const firstLogin = metadata[0].first_login;


      return {firstLogin};
    } catch (error) {
      set({ message: `Login error: ${error.message}` });
      return null;
    } finally {
      set({ loading: false });
    }
  },

  /**
   * @function logout - Logs out the user
   */
  logout: async () => {
    const { resetFleet } = useProForma.getState();
    set({ loading: true });
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      set({ user: null, data: [] });
      resetFleet();
    } catch (error) {
      set({ message: `Logout error: ${error.message}` });
    } finally {
      set({ loading: false });
    }
  },

  /**
   * @function isLoggedIn - Checks if the user is logged in
   */
  isLoggedIn: () => {
    const user = useAuthStore.getState().user;
    return user !== null;
  },


  /**
   * @function initializeAuth - Initializes the authentication state, sets the user and session state variables
   */
  initializeAuth: async () => {
    set({ loading: true });
    const {
      data: { session },
    } = await supabase.auth.getSession();
    if (session) {
      set({ user: session.user });
      set({session:session})
    }
    set({ loading: false });
  },
}));

export default useAuthStore;
