/**
 * This is the component for the site dropdown in the dashboard.
 * 
 * Parent: Dashboard.js
 */


import React, { useEffect, useState } from "react";
import useAuthStore from "../../store/useAuthStore";
import useCache from "../../store/useCache";
import useProForma from "../../store/useProForma";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";

const SiteDropdown = () => {
  const { user } = useAuthStore();
  const {
    controls,
    fleetData,
  } = useProForma();
  const { updateControls } = useCache();
  const [siteOptions, setSiteOptions] = useState([]);
  const [site, setSite] = useState("");

  useEffect(() => {
    if (controls.domiciles) {
      const tempSites = ["All Sites", ...controls.domiciles];
      setSite(controls["site"] || "");
      setSiteOptions(tempSites || "");
    }
  }, [controls, fleetData]);

  const updateControl = async (attribute, value) => {
    if (value === "" || value === null) return;
    try {
      updateControls(attribute, value);
      const response = await fetch(
        `${process.env.REACT_APP_API_ROUTE}api/controls/${user.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: useAuthStore.getState().getToken(),
          },
          body: JSON.stringify({ attribute, value }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      await response.json();
    } catch (error) {
      console.error(`Error updating control: ${error.message}`);
    }
  };

  const handleSiteChange = (str) => {
    const newValue = str;
    setSite(newValue);
    updateControl("site", newValue);
  };

  return (
    <DropdownMenu className="">
      <DropdownMenuTrigger asChild>
        <div className="inline-flex items-center text-xl lg:text-sm">
        <div className="text-[#40423f] font-semibold font-['Inter'] leading-normal">
            {controls && controls.site}
          </div>
          <img width={20} src="Keyboard arrow down.svg"></img>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuRadioGroup value={site} onValueChange={handleSiteChange}>
          {siteOptions?.map((option) => (
            <DropdownMenuRadioItem key={option} value={option}>
              {option}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default SiteDropdown;
