/**
 * @fileoverview Phases/Chargers outer level component
 */

import React from "react";
import { useState } from "react";
import useAuthStore from "../store/useAuthStore";
import ResetButton from "../components/ResetButton";
import PhaseGrid from "../components/phase-grid/PhaseGrid.js";
import AddPhaseButton from "../components/phase-grid/AddPhaseButton.js";
import { jsonToCsv } from "../utils/jsonToCsv";
import useProForma from "../store/useProForma";
import { Button } from "../components/ui/button";
import ToggleNav from "components/ToggleNav";

const filterOut = (obj) => {
  obj.forEach((item) => {
    delete item.user_id;
    delete item.id;
  });
  return obj;
};

function Phases() {
  const { user } = useAuthStore();
  const { phases } = useProForma();

  const [gridApi, setGridApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const filename = "phases.csv";

  /**
   * @function downloadCsv - Downloads the phases data as a CSV file
   */
  const downloadCsv = () => {
    const csv = jsonToCsv(filterOut(phases.phases));
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (!user) {
    return <div>Please log in</div>;
  }

  return (
    <div className="relative">
      <div className=" flex lg:flex-col lg:mb-4 items-center mb-4">
        <div className="flex items-center w-full justify-between p-5">
          <div>
            <div className="flex justify-between gap-2">
              <ToggleNav />
            </div>
            <div className="text-[#40423f] text-[23px] font-medium font-['Roboto'] leading-7">Chargers</div>
            <div className="text-[#8a8d89] text-[17px] font-medium font-['Inter'] leading-tight">Detailed data about your systems used to inform our advanced modeling</div>
          </div>
          <div className="bg-white p-4 rounded-full flex gap-2">
            <ResetButton tableName="phases" />
            <Button
              variant="outline"
              onClick={downloadCsv}
              className="rounded-full flex gap-2 bg-white"
            >
              <div className="text-[#7e817d] text-[13px] font-normal font-['Roboto'] leading-none flex gap-2 items-center">
                <img src="icons/exportIcon.svg"></img>Export to .csv
              </div>
            </Button>
          </div>
        </div>
      </div>
      <div className="flex flex-col rounded-3xl gap-6 bg-gray2 p-6 h-full">
        <PhaseGrid
          gridApi={gridApi}
          setGridApi={setGridApi}
          rowData={rowData}
          setRowData={setRowData}
        />
        <AddPhaseButton
          gridApi={gridApi}
          rowData={rowData}
          setRowData={setRowData}
        />
      </div>
    </div>
  );
}

export default Phases;
