import useAuthStore from "../store/useAuthStore";


/**
 * @function addAdvancedControl - Add advanced control to the database, called when first creating account
 * 
 * @param {*} defaultAdvancedControls default advanced controls to be created
 * @returns 
 */
export const addAdvancedControl = async (defaultAdvancedControls) => {
  const advancedControlRes = await fetch(
    `${process.env.REACT_APP_API_ROUTE}api/advancedcontrols/add`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: useAuthStore.getState().getToken(),
      },
      body: JSON.stringify(defaultAdvancedControls),
    }
  );

  return advancedControlRes;
};
