/**
 * Helper function for rounding numbers to the nearest thousand or million for graph rounding
 */


export const roundToNearest = (value) => {
    if (Math.abs(value) >= 1000000) {
      return {
        val: (Math.round((value / 1000000) * 100) / 100).toFixed(2),
        suffix: "M",
      };
    } else if (Math.abs(value) >= 1000) {
      return { val: Math.round(value / 1000), suffix: "K" };
    } else {
      return { val: Math.round(value).toString(), suffix: "" };
    }
  };
  