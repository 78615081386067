/**
 * This is the component for the cost benefit graph displayed in the dashboard.
 * 
 * Parent: Dashboard.js
 */

import React, { useEffect, useState } from "react";
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer,
  Bar,
  Cell,
  ComposedChart,
  ReferenceLine
} from "recharts";

import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import useProForma from "../../store/useProForma";
import useCache from "../../store/useCache";
import { axisHelper } from "../../utils/graphs/axisHelper";
import { axisStyle, labelStyle } from "./styles";
import { roundToNearest } from "utils/graphs/roundNumbers";
import useGraphState from "store/useGraphState";
import { PRIMARY_COLOR } from "colors";

const formatAsCurrency = (value) => {
  let roundedValue;
  let suffix;

  if (Math.abs(value) >= 1000000) {
    roundedValue = Math.round(value / 1000000);
    suffix = "M";
  } else if (Math.abs(value) >= 1000) {
    roundedValue = Math.round(value / 1000);
    suffix = "k";
  } else {
    roundedValue = Math.round(value);
    suffix = "";
  }

  const absValue = Math.abs(roundedValue)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return value < 0 ? `-$${absValue}${suffix}` : `$${absValue}${suffix}`;
};

const roundBoth = (min, max) => {
  const range = max - min;
  const minRounded = Math.floor((min - range * 0.1) / 100000) * 100000;
  const maxRounded = Math.ceil((max + range * 0.1) / 100000) * 100000;
  return { minRounded, maxRounded };
};

const transformData = (annualCostBenefit, cumulativeCostBenefit) => {
  const data = [];

  Object.keys(annualCostBenefit).forEach((year) => {
    data.push({
      year: parseInt(year, 10),
      annualCostBenefit: parseInt(annualCostBenefit[year]),
      cumulativeCostBenefit: parseInt(cumulativeCostBenefit[year]),
    });
  });

  return data;
};

const CostBenefitChart = () => {
  const {yearHovered} = useGraphState();
  const { yearOverYear, controls, cityInfo, years } = useProForma();
  const { annualCostBenefit = {}, cumulativeCostBenefit = {} } = yearOverYear;
  const { EVAL_YEAR } = years;

  const [minVal, setMinVal] = useState(0);
  const [maxVal, setMaxVal] = useState(0);
  const [annualVal, setAnnualVal] = useState(0);
  const [cumulativeVal, setCumulativeVal] = useState(0);
  const data = transformData(annualCostBenefit, cumulativeCostBenefit);

  useEffect(() => {
    const annualCostBenefitValues = Object.values(annualCostBenefit).map(
      (value) => parseInt(value, 10)
    );
    const cumulativeCostBenefitValues = Object.values(
      cumulativeCostBenefit
    ).map((value) => parseInt(value, 10));

    const minAnnualValue = Math.min(...annualCostBenefitValues);
    const maxAnnualValue = Math.max(...annualCostBenefitValues);

    const minCumulativeValue = Math.min(...cumulativeCostBenefitValues);
    const maxCumulativeValue = Math.max(...cumulativeCostBenefitValues);

    let minValue = Math.min(minAnnualValue, minCumulativeValue);
    let maxValue = Math.max(maxAnnualValue, maxCumulativeValue);

    const { minRounded, maxRounded } = roundBoth(minValue, maxValue);
    minValue = minRounded;
    maxValue = maxRounded;

    minValue =
      cityInfo && cityInfo.cost_benefit_min
        ? Math.min(cityInfo.cost_benefit_min, minValue)
        : minValue;
    maxValue =
      cityInfo && cityInfo.cost_benefit_max
        ? Math.max(cityInfo.cost_benefit_max, maxValue)
        : maxValue;

    setMinVal(minValue);
    setMaxVal(maxValue);

    setCumulativeVal(cumulativeCostBenefit[EVAL_YEAR]);
    setAnnualVal(annualCostBenefit[EVAL_YEAR]);
  }, [yearOverYear,EVAL_YEAR]);

  const getBarColor = (value) => (value >= 0 ? "#656864" : "#e65c5e");

  const customLegendPayload = [
    { value: "Annual Marginal Costs or Savings ($)", type: "rect", color: "#656864" },
    { value: "Annual Marginal Costs or Savings (-$)", type: "rect", color: "#e65c5e" },
    { value: "Cumulative Marginal Costs or Savings ($)", type: "rect", color: `${PRIMARY_COLOR}` },
  ];

  return (
    <div className="bg-white rounded-2xl p-4 flex flex-col h-[400px] lg:h-[500px] border">
      <div className="flex gap-2 text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal">
        <img src="cost-benefit-icon.svg" width="20"></img>Cost-Benefit Analysis
      </div>
      <div className="flex lg:flex-col h-full">
        <div className="flex-none flex flex-col lg:flex-row justify-start pt-4 gap-4 whitespace-nowrap w-[200px] lg:justify-around lg:w-full lg:mb-4">
          <div>
            <div className="flex items-end">
              <div className="text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal">
                $
              </div>
              <div className="text-[#1b1c1b] text-[35px] font-semibold font-['Inter'] leading-[42px]">
                {roundToNearest(annualVal).val}
              </div>
              <div className="text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal">
                {roundToNearest(annualVal).suffix}
              </div>
            </div>
            <div className="text-[#7e817d] text-[13px] font-normal font-['Roboto'] leading-none">
              Annual Marginal Cost, {EVAL_YEAR}
            </div>
          </div>
          <div>
            <div className="flex items-end">
              <div className="text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal">
                $
              </div>
              <div className="text-[#1b1c1b] text-[35px] font-semibold font-['Inter'] leading-[42px]">
                {roundToNearest(cumulativeVal).val}
              </div>
              <div className="text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal">
                {roundToNearest(cumulativeVal).suffix}
              </div>
            </div>
            <div className="text-[#7e817d] text-[13px] font-normal font-['Roboto'] leading-none">
              Cumulative Marginal Cost, {EVAL_YEAR}
            </div>
          </div>
        </div>
        <div className="flex-grow lg:h-[200px]">
          <ResponsiveContainer width="100%">
            <ComposedChart data={data} margin={{ left: 20 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" stroke="#7E817D" style={axisStyle} axisLine={false}/>
              <YAxis
                stroke="#7E817D"
                type="number"
                tickFormatter={formatAsCurrency}
                domain={[minVal, maxVal]}
                ticks={axisHelper(minVal, maxVal, 5)}
                style={axisStyle}
                axisLine={false}
              >
                <Label
                  value="Annual Marginal Costs or Savings ($)"
                  angle={-90}
                  position="left"
                  style={labelStyle}
                />
              </YAxis>
              <ReferenceLine x={yearHovered??EVAL_YEAR} stroke={PRIMARY_COLOR} />
              <Tooltip formatter={formatAsCurrency} />
              <Legend align="right" payload={customLegendPayload} wrapperStyle={{fontSize: "12px"}} />
              <Bar
                radius={[5, 5, 5, 5]}
                name="Annual Marginal Costs or Savings ($)"
                dataKey="annualCostBenefit"
                fill=""
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={getBarColor(entry.annualCostBenefit)}
                  />
                ))}
              </Bar>
              <Line
                strokeWidth={2}
                type="monotone"
                dataKey="cumulativeCostBenefit"
                stroke={PRIMARY_COLOR}
                name="Cumulative Marginal Costs or Savings ($)"
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default CostBenefitChart;
