/**
 * @fileoverview This file contains utility functions for updating advanced controls.
 * It does two things: update the database with an API request, and update the cache (updateAdvancedControls)
 */

import useCache from "store/useCache";
import useAuthStore from "store/useAuthStore";

export const useUpdateAdvancedControl = () => {
  const { updateAdvancedControls } = useCache();

  const updateAdvancedControl = async (id, updatePayload) => {
    try {
      updateAdvancedControls(updatePayload)
      const response = await fetch(
        `${process.env.REACT_APP_API_ROUTE}api/advancedcontrols/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: useAuthStore.getState().getToken(),
          },
          body: JSON.stringify({ id, ...updatePayload }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update advanced control");
      }
      await response.json();
    } catch (error) {
      console.error("Error updating advanced control:", error);
      throw error;
    }
  };

  return updateAdvancedControl;
};
