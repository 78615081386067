/**
 * This component contains the total costs graph for the dashboard.
 */


import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import useProForma from "store/useProForma";
import useCache from "store/useCache";
import { axisHelper } from "utils/graphs/axisHelper";
import { axisStyle, labelStyle } from "./styles";
import { roundToNearest } from "utils/graphs/roundNumbers";
import { PRIMARY_COLOR,SECONDARY_GRAPH_COLOR } from "colors";
import ControlLabel from "../advanced-controls/ControlLabel";

const formatAsCurrency = (value) => {
  let roundedValue;
  let suffix;

  if (Math.abs(value) >= 1000000) {
    roundedValue = (value / 1000000).toFixed(1);
    suffix = "M";
  } else if (Math.abs(value) >= 1000) {
    roundedValue = (value / 1000).toFixed(1);
    suffix = "k";
  } else {
    roundedValue = value.toFixed(1);
    suffix = "";
  }

  const absValue = Math.abs(roundedValue)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return value < 0 ? `-$${absValue}${suffix}` : `$${absValue}${suffix}`;
};


const roundedValue = (value) => {
  return Math.ceil((value * 1.2) / 100000) * 100000;
};

const TotalCostsGraph = () => {
  const { yearOverYear, controls, cityInfo } = useProForma();
  const {
    totalZevAndEvseCosts = {},
    totalDefaultReplacementCosts = {},
    totalCapitalCosts = {},
    netPresentValue,
  } = yearOverYear;
  const [totalCost, setTotalCost] = useState(0);
  const [totalSaving, setTotalSaving] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [capitalCostSum, setCapitalCostSum] = useState(0);
  const [ticks, setTicks] = useState([]);

  const sumValues = (data) => {
    let sum = 0;
    for (const year in data) {
      if (year === "title") {
        continue;
      }
      sum += data[year];
    }
    return sum;
  };

  useEffect(() => {
    const totalCostSum = sumValues(totalZevAndEvseCosts);
    const totalSavingsSum = sumValues(totalDefaultReplacementCosts);
    const capitalCostSum = sumValues(totalCapitalCosts);
    setTotalCost(totalCostSum);
    setTotalSaving(totalSavingsSum);
    setCapitalCostSum(capitalCostSum);

    let maxCostSaving = Math.max(
      roundedValue(totalCostSum),
      roundedValue(totalSavingsSum),
      cityInfo.cost_savings_max
    );
    maxCostSaving = Math.round(maxCostSaving);
    setMaxValue(maxCostSaving);
    setTicks(axisHelper(0, maxCostSaving, 5));
  }, [yearOverYear]);

  const data = [
    {
      name: "Cumulative Values",
      cost: parseInt(totalCost),
      savings: parseInt(totalSaving),
    },
  ];

  return (
    <div className="bg-white rounded-2xl p-4 flex flex-col gap-5 border">
      <div>
        <div className="flex gap-2 text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal">
          <img src="Finance chip.svg" width="20"></img>Total Costs
        </div>
      </div>
      <div className="flex justify-start gap-6">
        <div>
          <div className="flex items-end">
            <div className="text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal">
              $
            </div>
            <div className="text-[#1b1c1b] text-[29px] font-semibold font-['Inter'] leading-[34.80px]">
              {roundToNearest(netPresentValue).val}
            </div>
            <div className="text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal">
              {roundToNearest(netPresentValue).suffix}
            </div>
          </div>
          <ControlLabel 
            text={<span className="text-xs">Net Present Value</span>} // Smaller text
            info='NPV is the difference between the present value of cash inflows and the present value of cash outflows over a specified period of time.' />
          {/* <div className="text-[#7e817d] text-[13px] font-normal font-['Roboto'] leading-none flex items-center relative">
            Net Present Value
            <div
              className="ml-2 cursor-pointer relative flex items-center"
              onMouseEnter={(e) => {
                const tooltip = document.getElementById("npv-tooltip");
                tooltip.style.display = "block";
                tooltip.style.left = `${e.clientX}px`;
                tooltip.style.top = `${e.clientY + 20}px`;
              }}
              onMouseLeave={() => {
                const tooltip = document.getElementById("npv-tooltip");
                tooltip.style.display = "none";
              }}
            >
              <div
                className="w-6 h-6 flex items-center justify-center rounded-full border border-gray-300"
                style={{ backgroundColor: "#f5f5f5" }}
              >
                <span className="text-gray-600 text-xs">i</span>
              </div>
              <div
                id="npv-tooltip"
                style={getTooltipStyle("Net Present Value")}
              >
                NPV is the difference between the present value of cash inflows and the present value of cash outflows over a period of time.
              </div>
            </div> */}
          {/* </div> */}
        </div>
        <div>
          <div className="flex items-end">
            <div className="text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal">
              $
            </div>
            <div className="text-[#1b1c1b] text-[29px] font-semibold font-['Inter'] leading-[34.80px]">
            {roundToNearest(capitalCostSum).val}
            </div>
            <div className="text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal">
            {roundToNearest(capitalCostSum).suffix}
            </div>
          </div>
          {/* <div className="text-[#7e817d] text-[13px] font-normal font-['Roboto'] leading-none">
            Total Capital Costs
          </div> */}
           <ControlLabel 
            text={<span className="text-xs">Total Capital Costs</span>} // Smaller text
            info='Total Capital Costs is the zero-emission vehicles (ZEVs) and electric vehicle supply equipment (EVSE) capital expenditure costs.' />
        </div>
      </div>

      <ResponsiveContainer width="100%" height={400}>
        <BarChart barGap={12} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            tick={false}
            style={axisStyle}
            stroke="#7E817D"
          />
          <YAxis
            stroke="#7E817D"
            style={axisStyle}
            domain={[0, maxValue]}
            ticks={ticks}
            tickFormatter={formatAsCurrency}
          ></YAxis>
          {/* <Tooltip formatter={formatAsCurrency} /> */}
          <Legend  wrapperStyle={{ fontSize: "12px" }} />
          <Bar
            radius={[5, 5, 5, 5]}
            dataKey="cost"
            fill={PRIMARY_COLOR}
            name="ZEV & EVSE TCO"
          >
            <LabelList
              dataKey="cost"
              position="top"
              formatter={formatAsCurrency}
            />
          </Bar>
          <Bar
            radius={[5, 5, 5, 5]}
            dataKey="savings"
            fill={SECONDARY_GRAPH_COLOR}
            name="Default Replacement Costs"
          >
            <LabelList
              dataKey="savings"
              position="top"
              formatter={formatAsCurrency}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TotalCostsGraph;
