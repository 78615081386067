import useAuthStore from "../store/useAuthStore";


/**
 * @function addChargerCost - Charger Cost added, called when initializing new user
 * @param {*} userId - userId to be updated
 * @returns api response
 */
export const addChargerCost = async (userId) => {

  const response = await fetch(
    `${process.env.REACT_APP_API_ROUTE}api/chargerdata/add/${userId}`,{headers: {
      Authorization: useAuthStore.getState().getToken(),
    },}
  );
  return response;
};
