/**
 * This is the component for the capital costs graph displayed in the dashboard.
 * 
 * Parent: Dashboard.js
 */

import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  ReferenceLine,
} from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import useProForma from "store/useProForma";
import { axisHelper } from "utils/graphs/axisHelper";
import useCache from "store/useCache";
import { axisStyle, labelStyle } from "./styles";
import { roundToNearest } from "utils/graphs/roundNumbers";
import useGraphState from "store/useGraphState";
import { useMediaQuery } from "react-responsive";
import { PRIMARY_COLOR,SECONDARY_GRAPH_COLOR } from "colors";




const roundedValue = (value, up = true) => {
  return up
    ? Math.ceil((value * 1.2) / 100000) * 100000
    : Math.floor((value * 1.2) / 100000) * 100000;
};

const transformData = (totalCosts) => {
  const data = [];

  Object.keys(totalCosts).forEach((year) => {
    data.push({
      year: parseInt(year, 10),
      totalCosts: totalCosts[year],
    });
  });

  return data;
};

const formatAsCurrency = (value) => {
  let roundedValue;
  let suffix;

  if (Math.abs(value) >= 1000000) {
    roundedValue = Math.round(value / 1000000);
    suffix = "M";
  } else if (Math.abs(value) >= 1000) {
    roundedValue = Math.round(value / 1000);
    suffix = "k";
  } else {
    roundedValue = Math.round(value);
    suffix = "";
  }

  const absValue = Math.abs(roundedValue)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return value < 0 ? `-$${absValue}${suffix}` : `$${absValue}${suffix}`;
};

const sumValues = (data) => {
  let sum = 0;
  for (const year in data) {
    if (year === "title") {
      continue;
    }
    sum += data[year];
  }
  return sum;
};

const CapitalCostsGraph = () => {
  const {yearHovered} = useGraphState();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });
  


  const { yearOverYear, controls, allSitesYearOverYear, cityInfo, years } =
    useProForma();
  const { EVAL_YEAR } = years;
  const { totalCapitalCosts: yearOverYearTotalCapitalCosts = {} } =
    allSitesYearOverYear;
  const { totalCapitalCosts = {} } = yearOverYear;
  const data = transformData(totalCapitalCosts);
  const data2 = transformData(yearOverYearTotalCapitalCosts);
  //state variables
  const [maxVal, setMaxVal] = useState(0);

  useEffect(() => {
    let max = 0;
    Object.values(yearOverYearTotalCapitalCosts).forEach((value) => {
      if (value > max) {
        max = value;
      }
    });
    let maxRounded = roundedValue(max);

    setMaxVal(Math.max(maxRounded, cityInfo.capital_costs_max));
  }, [allSitesYearOverYear]);

  useEffect(()=>{
    sumValues(totalCapitalCosts)
  },[totalCapitalCosts])

  return (
    <div className="bg-white rounded-2xl p-4 gap-4 flex flex-col h-[230px] lg:h-[300px] border">
      <div className="flex gap-2 text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal">
        <img src="icons/capitalcostsicon.svg" width="18"></img>Capital Costs
      </div>
      <div className="flex lg:flex-col lg:gap-2">
        <div className="flex-none flex flex-col justify-start gap-4 w-[200px] lg:justify-start lg:w-3/4 lg:m-auto lg:flex-row">
          <div>
            <div className="flex items-end">
              <div className="text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal">
                $
              </div>
              <div className="text-[#1b1c1b] text-[35px] font-semibold font-['Inter'] leading-[42px]">
                {roundToNearest(totalCapitalCosts[EVAL_YEAR]).val}
              </div>
              <div className="text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal">
                {roundToNearest(totalCapitalCosts[EVAL_YEAR]).suffix}
              </div>
            </div>
            <div className="text-[#7e817d] text-[13px] font-normal font-['Roboto'] leading-none">
              Capital costs, {EVAL_YEAR}
            </div>
          </div>
          <div>
            <div className="flex items-end">
              <div className="text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal">
                $
              </div>
              <div className="text-[#1b1c1b] text-[35px] font-semibold font-['Inter'] leading-[42px]">
                {roundToNearest(sumValues(totalCapitalCosts)).val}
              </div>
              <div className="text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal">
                {roundToNearest(sumValues(totalCapitalCosts)).suffix}
              </div>
            </div>
            <div className="text-[#7e817d] text-[13px] font-normal font-['Roboto'] leading-none">
            Total Capital Costs

            </div>
          </div>
        </div>
        <div className="flex-grow lg:pt-2">
          <ResponsiveContainer className="" width="100%" height={160}>
            <LineChart data={data} margin={{ left: 20 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                stroke="#7E817D"
                dataKey="year"
                style={axisStyle}
                axisLine={false}
              />
              <YAxis
                stroke="#7E817D"
                minTickGap={-10}
                type="number"
                domain={[0, maxVal]}
                ticks={axisHelper(0, maxVal, 4)}
                tickFormatter={formatAsCurrency}
                style={axisStyle}
                axisLine={false}
              >
               {!isTabletOrMobile && <Label
                  value="Total Costs ($)"
                  angle={-90}
                  position="left"
                  style={labelStyle}
                />}
              </YAxis>
              <Tooltip formatter={formatAsCurrency} />
              <Legend align="right" wrapperStyle={{ fontSize: "12px" }} />
              <ReferenceLine x={yearHovered??EVAL_YEAR} stroke={PRIMARY_COLOR} />
              {controls.site !== "All Sites" && (
                <Line
                  strokeWidth={2}
                  type="monotone"
                  dataKey="totalCosts"
                  stroke={SECONDARY_GRAPH_COLOR}
                  name={`${controls.site} Total Costs ($)`}
                />
              )}

              <Line
                strokeWidth={2}
                type="monotone"
                dataKey="totalCosts"
                stroke={PRIMARY_COLOR}
                name="All Sites Total Costs ($)"
                data={data2}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default CapitalCostsGraph;
