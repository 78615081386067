/**
 * @fileoverview This file contains the function that fetches all data from the database and calls function to perform calculations on the data.
 * 
 * 
 */

import { proFormaCalcs, calcYears } from "./proFormaCalcs";
import { yearOverYear } from "./yearOverYear";
import { calculatePhases } from "./phaseCalcs";
import fetchData from "./fetchData";


/**
 * @function called by fetchAndUpdateFleet from useProForma.js to retreive all data from database
 * @param {userId} user id to update the data for
 * @param {field} field to update, if none is passed, only fetch from cache
 * @returns 
 */
export const getFleetData = async (userId,field) => {
  try {
    // Fetch data from both tables concurrently
    let fleetData, advancedControls, controls, phases, chargerCost, cityInfo;
    const data = await fetchData(userId,field);
    advancedControls = data.advancedControls;
    controls = data.controls;
    phases = data.phases;
    chargerCost = data.chargerCost;
    cityInfo = data.cityInfo;
    fleetData = data.fleetData;


    // Perform calculations on fleetData
    fleetData.forEach((vehicle) => {
      vehicle.id = String(vehicle.equipment_id);
    });
    fleetData.sort((a, b) => a.equipment_id - b.equipment_id);

    let result = {};
    result.cityInfo = cityInfo;
    
    
    result.fleetData = fleetData;
    if (controls.site === "") {
      controls.site = "All Sites";
    }
    if (controls.electrification_scenario === "") {
      const options = Object.keys(fleetData[0].electrification_scenario);
      controls.electrification_scenario = options[0];
    }

    const years = calcYears(result.fleetData, phases,cityInfo.year_max);
    let allSitesControls = { ...controls };
    allSitesControls.site = "All Sites";
    result.years = years;
    result.proFormaCalcs = proFormaCalcs(
      advancedControls,
      controls,
      result.fleetData,
      years
    );

    result.chargerCost = chargerCost;
    result.phases = calculatePhases(phases, chargerCost, controls);
    result.yearOverYear = yearOverYear(
      result.fleetData,
      result.proFormaCalcs,
      controls,
      advancedControls,
      years,
      result.phases
    );
    result.allSitesProFormaCalcs = proFormaCalcs(
      advancedControls,
      allSitesControls,
      result.fleetData,
      years
    );
    let allSitesPhases = result.phases;
    allSitesPhases.filteredPhases = [...allSitesPhases.phases];
    result.allSitesYearOverYear = yearOverYear(
      result.fleetData,
      result.allSitesProFormaCalcs,
      allSitesControls,
      advancedControls,
      years,
      allSitesPhases
    );
    result.controls = setControlsData(controls, fleetData);

    result.advancedControls = advancedControls;
    // Send the result back to the client

    return result;
  } catch (error) {
    console.error("Error fetching data:",error)
  }
};

const setControlsData = (controls, fleetData) => {
  const uniqueDomiciles = [
    ...new Set(fleetData.map((item) => item["Simplified Domicile"])),
  ];
  controls.domiciles = uniqueDomiciles;
  return controls;
};
